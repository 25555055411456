import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PulseLoader from 'react-spinners/ClipLoader';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

const Home = ({ setName }) => {
  // Get today's date
  const today = new Date();

  // Extract day, month, and year from the date object
  const day = String(today.getDate()).padStart(2, '0'); // Ensure two-digit day with leading zero if necessary
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed, so add 1
  const year = today.getFullYear();

  // Format the date as dd-mm-yyyy
  const formattedDate = `${day}-${month}-${year}`;

  // Set up state with an object containing the date property initialized to today's date
  // const [dateData, setDateData] = useState({
  //   date: formattedDate,
  // });
  const [loader, setLoader] = useState(false);
  const [retry, setRetry] = useState(false);

  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Phone: '',
    College: '',
    Academic_year: '',
    Department: '',
    date: formattedDate,
  });

  const [errors, setErrors] = useState({
    Name: false,
    Email: false,
    Phone: false,
    College: false,
    Academic_year: false,
    Department: false,
  });

  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [showOtpVerification, setShowOtpVerification] = useState(false); // State to control OTP verification display
  const navigate = useNavigate();

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  //   // console.log(formData);
  //   setErrors({
  //     ...errors,
  //     [name]: false,
  //   });
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };

    // Update the form data with the new input value
    setFormData({
      ...formData,
      [name]: value,
    });

    // If the field is "Phone" and its value doesn't have exactly 10 numbers, set error to true
    if (name === 'Phone' && !/^\d{10}$/.test(value.trim())) {
      newErrors[name] = true;
    } else {
      // Clear error for other fields if user starts typing
      newErrors[name] = false;
    }

    // Update errors state
    setErrors(newErrors);
  };

  // For otp==========
  // function generateOTP() {
  //   const digits = '0123456789';
  //   let OTP = '';
  //   for (let i = 0; i < 4; i++) {
  //     OTP += digits[Math.floor(Math.random() * 10)];
  //   }
  //   return OTP;
  // }

  // For otp==========
  // const sendOtp = async () => {
  //   const otpC = generateOTP();
  //   sessionStorage.setItem('token', otpC);

  //   setTimeout(() => {
  //     setRetry(true);
  //   }, 60000);

  //   const phone = formData.Phone;
  //   try {
  //     const response = await axios.get('https://control.msg91.com/api/v5/otp', {
  //       params: {
  //         template_id: '620ded246e6d880a3509fac2',
  //         mobile: 91 + phone,
  //         authkey: '372378AfS3xSHC6B620e113cP1',
  //         otp: otpC,
  //       },
  //     });

  //     // console.log(response.data); // Handle response data accordingly
  //   } catch (error) {
  //     console.error('Error sending OTP:', error);
  //   }

  //   window.scrollTo({
  //     top: document.documentElement.scrollHeight,
  //     behavior: 'smooth', // Optional: smooth scrolling animation
  //   });
  // };

  // =========================For otp======================
  const start = async (event) => {
    event.preventDefault();
    const newErrors = {};
    let hasError = false;

    // working fine validation
    // for (const key in formData) {
    //   if (formData[key].trim() === '') {
    //     newErrors[key] = true;
    //     hasError = true;
    //   } else if (key === 'Phone' && formData[key].trim().length !== 10) {
    //     // Check if Phone field is not empty and doesn't have exactly 10 digits
    //     newErrors[key] = true;
    //     hasError = true;
    //   } else if (['Name', 'College', 'Department'].includes(key)) {
    //     // Check if Name, College, or Department contains only characters
    //     const isValid = /^[A-Za-z\s]+$/.test(formData[key].trim());
    //     if (!isValid) {
    //       newErrors[key] = true;
    //       hasError = true;
    //     }
    //   }
    // }

    // test valiadation
    for (const key in formData) {
      if (formData[key].trim() === '') {
        newErrors[key] = true;
        hasError = true;
      } else if (key === 'Phone') {
        const phoneValue = formData[key].trim();

        // Check if Phone field doesn't have exactly 10 digits or contains non-digit characters
        if (phoneValue.length !== 10 || !/^[0-9]+$/.test(phoneValue)) {
          newErrors[key] = true;
          hasError = true;
        } else {
          // Check if the sum of digits of Phone is zero
          const digits = phoneValue.split('').map(Number); // Convert each character to a number
          const sumOfDigits = digits.reduce((sum, digit) => sum + digit, 0);

          if (sumOfDigits === 0) {
            newErrors[key] = true;
            hasError = true;
          }
        }
      } else if (key === 'Academic_year') {
        // Check if Academic_year contains only numeric characters
        const academicYearValue = formData[key].trim();

        if (!/^[0-9]+$/.test(academicYearValue)) {
          newErrors[key] = true;
          hasError = true;
        }
      } else if (['Name', 'College', 'Department'].includes(key)) {
        // Check if Name, College, or Department contains only alphabetic characters with optional whitespace
        const isValid = /^[A-Za-z\s]+$/.test(formData[key].trim());
        if (!isValid) {
          newErrors[key] = true;
          hasError = true;
        }
      }
    }

    if (!hasError) {
      // for otp=====================
      // setName(formData.Name);
      // localStorage.setItem('formData', JSON.stringify(formData));
      // sendOtp();
      // setShowOtpVerification(true); // Show OTP verification section

      // Check if the user already attended the exam based on Name and Phone
      const storedFormDataJSON = localStorage.getItem('formData');
      if (storedFormDataJSON) {
        const storedFormData = JSON.parse(storedFormDataJSON);
        const { Name: storedName, Phone: storedPhone } = storedFormData;
        const { Name, Phone } = formData;

        // Check for duplicate based on exact Name and Phone combination
        if (storedName === Name && storedPhone === Phone) {
          // Show toast for duplicate exact entry
          toast.error(
            'You have already attended the exam with this Name and Phone.',
            {
              // position: 'bottom-center',
              position: 'center-right',
              duration: 6000,
              style: {
                width: '400px',
                fontSize: '24px',
                background: 'green',
                color: '#fff',
              },
            }
          );
          return; // Stop further execution
        }

        // Check for duplicate Phone number irrespective of Name
        if (storedPhone === Phone) {
          // Show toast for duplicate Phone number
          toast.error(
            'This Phone number has already been used to attend the exam.',
            {
              // position: 'bottom-center',
              position: 'center-right',
              duration: 6000,
              style: {
                width: '400px',
                fontSize: '24px',
                background: 'green',
                color: '#fff',
              },
            }
          );
          return; // Stop further execution
        }
      }
      console.log(formData.date);
      // w/o otp===============
      const formDatas = new FormData();

      Object.keys(formData).forEach((key) => {
        formDatas.append(key, formData[key]);
      });
      // console.log(formData);
      (async function callApi() {
        console.log('sucessssssssssssssss');
        setLoader(true);
        const formDatass = new FormData();
        formDatass.append('Name', formData.Name);
        formDatass.append('Email', formData.Email);
        formDatass.append('Phone', formData.Phone);
        formDatass.append('College', formData.College);
        formDatass.append('Academic_year', formData.Academic_year);
        formDatass.append('Department', formData.Department);
        formDatass.append('Date', formData.date);
        const formDataJSON = JSON.stringify(formData);
        // localStorage.setItem('formData', formDataJSON);
        //wOA-TESt API
        axios
          .post(
            'https://script.google.com/macros/s/AKfycbwUmv5PlsKjbxvsqIdwVg4TbBmPbo5lFeGK0Pk_S7HpVGUv5HPtBZEvTH6Sr4Irs1YruQ/exec',
            // 'https://script.google.com/macros/s/AKfycbyAWz1h8bOyieYmqtYjOcbJy47UNPTTFSOdjwYzAFG2sJb_ia10wLhgZPDvIVKCF0hwOA/exec',
            formDatas
          )
          .then((data) => {
            console.log(data);
            localStorage.setItem('formData', formDataJSON);

            navigate('/instructions');
          })
          .catch((error) => {
            console.log(error);
          });
      })();
    }

    setErrors(newErrors);
  };

  // for otp===================
  // const verifyOtp = () => {
  //   // For otp======
  //   // const storedOtp = sessionStorage.getItem('token');

  //   // For otp======
  //   // if (otp === storedOtp) {
  //   const formDatas = new FormData();

  //   Object.keys(formData).forEach((key) => {
  //     formDatas.append(key, formData[key]);
  //   });
  //   // console.log(formData);
  //   (async function callApi() {
  //     // console.log('sucessssssssssssssss');
  //     axios
  //       .post(
  //         'https://script.google.com/macros/s/AKfycbwUmv5PlsKjbxvsqIdwVg4TbBmPbo5lFeGK0Pk_S7HpVGUv5HPtBZEvTH6Sr4Irs1YruQ/exec',
  //         // 'https://script.google.com/macros/s/AKfycbyq09czKL43ThH2bz7csEdzb-99_IdCBDIHnW0-kdR3G9LlmRC_X2j-o-LhUE6pktYs_A/exec',
  //         formDatas
  //       )
  //       .then((data) => {
  //         // console.log(data);
  //       })
  //       .catch((error) => {
  //         // console.log(error);
  //       });
  //   })();
  //   const formDatass = new FormData();
  //   formDatass.append('Name', formData.Name);
  //   formDatass.append('Email', formData.Email);
  //   formDatass.append('Phone', formData.Phone);
  //   formDatass.append('College', formData.College);
  //   formDatass.append('Academic_year', formData.Academic_year);
  //   formDatass.append('Department', formData.Department);
  //   const formDataJSON = JSON.stringify(formData);
  //   localStorage.setItem('formData', formDataJSON);
  //   navigate('/instructions');
  // For otp======
  // } else {
  //   setOtpError('Wrong OTP. Please try again.');
  // }
  // };

  // for otp========
  // const resendOtp = () => {
  //   sendOtp();
  //   setOtpError('');
  // };

  return (
    <div className="Main_Div">
      <Toaster>
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              ...t.style,
              animation: t.visible
                ? 'custom-enter 3s ease'
                : 'custom-exit 1s ease',
            }}
          />
        )}
      </Toaster>
      <div className="Home_Div">
        <form onSubmit={start}>
          <div className="logo-container">
            <img
              className="logo"
              src="/company_logo_edited1.png"
              alt=""
              width={'400'}
              height={'109'}
              // style={{ textAlign: 'center' }}
            />
          </div>
          <h1>Assessment Exam</h1>
          <p className="first_p">Enter your details to start the test</p>
          <input
            type="text"
            name="Name"
            value={formData.Name}
            placeholder="Your name"
            autoComplete="off"
            onChange={handleInputChange}
          />
          {errors.Name && <p className="error">Please enter your name</p>}
          <input
            type="email"
            name="Email"
            value={formData.Email}
            placeholder="Your email"
            autoComplete="off"
            onChange={handleInputChange}
          />
          {errors.Email && <p className="error">Please enter a valid email</p>}
          <input
            type="text"
            name="College"
            value={formData.College}
            placeholder="Your college"
            autoComplete="off"
            onChange={handleInputChange}
          />
          {errors.College && <p className="error">Please enter your college</p>}
          <input
            type="text"
            name="Academic_year"
            value={formData.Academic_year}
            placeholder="Your academic year"
            autoComplete="off"
            onChange={handleInputChange}
          />
          {errors.Academic_year && (
            <p className="error">Please enter your academic year</p>
          )}
          <input
            type="text"
            name="Department"
            value={formData.Department}
            placeholder="Your department"
            autoComplete="off"
            onChange={handleInputChange}
          />
          {errors.Department && (
            <p className="error">Please enter your department</p>
          )}
          <input
            type="tel"
            name="Phone"
            value={formData.Phone}
            placeholder="Your phone"
            autoComplete="off"
            maxLength="10"
            onChange={handleInputChange}
            // onChange={(e) => {
            //   const { value } = e.target;
            //   // Check if the entered value starts with '91'
            //   if (value.startsWith('91')) {
            //     setFormData({ ...formData, phone: value });
            //   } else {
            //     // If not, prepend '91' to the entered value
            //     setFormData({ ...formData, phone: '91' + value });
            //   }
            // }}
          />
          {/* {errors.Phone && (
            <p className="error">Please enter a valid Phone number</p>
          )} */}
          {errors.Phone && (
            <p className="error">Please enter a 10-digit phone number</p>
          )}

          <div style={{ textAlign: 'center' }}>
            {loader === true ? (
              <PulseLoader color="#36d7b7" size={40} />
            ) : (
              <></>
            )}
          </div>
          <br />
          <button type="submit">Click here to start</button>
          <br />
        </form>

        {/* for otp======= */}
        {/* {showOtpVerification && (
          <>
            <div>
              <h2 style={{ color: 'white', textAlign: 'center' }}>Enter OTP</h2>
              <p style={{ fontSize: '16px' }}>sent to your mobile</p>
              <input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
                maxLength="4"
              />
              {otpError && <p className="error">{otpError}</p>}

              <button type="button" onClick={verifyOtp}>
                Verify OTP
              </button>
              <br />
              {retry === true ? (
                <button type="button" onClick={resendOtp}>
                  Send OTP again
                </button>
              ) : (
                ''
              )}
            </div>
          </>
        )} */}
        {/* for otp======= */}
      </div>
    </div>
  );
};

export default Home;
