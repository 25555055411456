import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Timer = ({
  setQNumber,
  setSeconds,
  seconds,
  qNumber,
  quizQuestions,
  setDisplayScore,
  score,
}) => {
  const data = localStorage.getItem('formData');
  const datas = JSON.parse(data);
  datas['score'] = score;

  const [displayTime, setDisplayTime] = useState({ minutes: 0, seconds: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    let interval = null;
    interval = setInterval(async () => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }

      if (seconds === 0) {
        setDisplayScore(true);
      }

      // Convert seconds to minutes and seconds format
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      setDisplayTime({ minutes, seconds: remainingSeconds });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // }, [seconds, setDisplayScore]);
  }, [
    seconds,
    setQNumber,
    setSeconds,
    qNumber,
    quizQuestions.length,
    setDisplayScore,
  ]);

  return (
    // <h5 className="time">
    //   Time Left -{' '}
    //   {displayTime.minutes < 10
    //     ? `0${displayTime.minutes}`
    //     : displayTime.minutes}{' '}
    //   :{' '}
    //   {displayTime.seconds < 10
    //     ? `0${displayTime.seconds}`
    //     : displayTime.seconds}
    // </h5>

    <h5 className="time">
      Time Left -{' '}
      {displayTime.minutes < 10
        ? `0${displayTime.minutes}`
        : displayTime.minutes}{' '}
      :{' '}
      {displayTime.seconds < 10
        ? `0${displayTime.seconds}`
        : displayTime.seconds}
    </h5>
  );
};

export default Timer;
